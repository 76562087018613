import Box from "@mui/system/Box"
import { styled } from '@mui/material/styles';
import useLoginServices from "../../../customHooks/useLoginServices";
import { useEffect, Fragment } from "react";
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';

interface bubbleProps {
    own: string,
    position: string
}

interface infoProps {
    msgtype: number
}


const MessageBubble = styled(ListItem)<bubbleProps>(({ theme, own, position }) => ({
    padding: "0px",
    display: 'flex',
    justifyContent: Boolean(own) ? 'flex-end' : undefined,
    marginBottom: '1px',
    '& .MuiListItemAvatar-root': {
        visibility: (Boolean(own) || (position !== "last")) ? 'hidden' : undefined,
        marginRight: '-10px'
    },
    '& .MuiBox-root': {
        backgroundColor: Boolean(own) ? theme.palette.grey[300] : theme.palette.info.light,
        color: !Boolean(own) ? theme.palette.grey[200] : undefined,
        fontFamily: theme.typography.fontFamily,
        padding: "10px",
        borderRadius: "1em",
        borderTopLeftRadius: (((position === "first") || (position === "mid")) && !Boolean(own)) && "0.1em",
        borderBottomLeftRadius: (((position === "last") || (position === "mid")) && !Boolean(own)) && "0.1em",
        borderBottomRightRadius: (((position === "last") || (position === "mid")) && Boolean(own)) && "0.1em",
        borderTopRightRadius: (((position === "first") || (position === "mid")) && Boolean(own)) && "0.1em",
        maxWidth: "70%",
        align: 'right',
        height: "auto",
        overfloX: "hidden",
        wordWrap: "break-word",
    }
}))


const Info = styled(Typography)<infoProps>(({ theme, msgtype }) => ({
    alignSelf: msgtype !== 4 ? "center" : "end",
    marginTop: msgtype === 2 ? "30px": "0px",
    ...(msgtype === 1) ? theme.typography.body2 : theme.typography.subtitle2
}))



interface user {
    _id: string,
    username: string,
    imageid: string
}

interface message {
    createdAt: string,
    _id: string,
    message: string, 
    type: number,
    updatedAt: string,
    user: user,
}
interface slice {
    slice: Array<message>
}



const MessageSlice = ({slice}: slice) => {


    const { loginState } = useLoginServices()
    
    return (
        <Fragment>
            {slice.map(({user, message, type}, index) => 
        (type === 0) ? (
            <MessageBubble 
            key={index}
            own={(loginState.username === user.username) ? 'true' : 'false'}
            position={((slice.length === 1) && "last") || ((index === 0) && "first") || ((index === slice.length-1) && "last") || "mid"}>
                <ListItemAvatar>
                    <Avatar alt={user.username.charAt(0)} src={`/images/${user.imageid}`} />
                </ListItemAvatar>
                <Box>{message}</Box>
            </MessageBubble> 
        ) : (
            <Info key={index} msgtype={type}>{message}</Info>
        )
    )}
        </Fragment>
    )
}

export default MessageSlice