import { useEffect, useState, createContext } from 'react';
import _ from 'lodash';
import ChatSelector from './ChatSelector/ChatSelector';
import ChatMenu from './ChatMenu/ChatMenu';
import Box from "@mui/material/Box";
import ChatWindow from './ChatWindow/ChatWindow';
import { CssBaseline } from '@mui/material';
import ChatApp from '../customHooks/ChatApp';
import { ChatInstance } from '../Class/Class';


//const instance = new ChatInstance()

const Chat = () => {

 

    return (
        
        <ChatApp>
            <CssBaseline>
            <Box sx={{display: "grid", gridTemplateColumns: {xs: "auto", md: "270px auto 270px"}, height: "100vh", width: "100vw"}}>
                <Box sx={{display: { xs: "none", md: "flex" }, width: "270px", height: "100vh", flexDirection: "column"}}>
                    <ChatSelector/>
                </Box>
                <Box sx={{minWidth: 0}}>
                    <ChatWindow />
                </Box>
                <Box sx={{display: { xs: "none", md: "block" }, padding: "5px", width: "270px",}}>
                    <ChatMenu />
                </Box>
            </Box>
            </CssBaseline>
        </ChatApp>
    )
}

export default Chat