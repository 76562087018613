import Box from "@mui/system/Box"
import { useEffect, useState, ChangeEventHandler, FormEventHandler, MouseEventHandler, ElementType  } from 'react';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import { styled } from '@mui/material/styles';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import emojis from 'emojibase-data/hu/data.json';
import { checkText } from 'smile2emoji'
import { Emoji } from 'emojibase';
import useChat from "../../../customHooks/useChat";



const CustomSendIcon = styled(SendIcon)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main, 
    color: "#fff", 
    padding: '5px', 
    borderRadius: '5px', 
    height: '40px', 
    width: '40px',
}))

const MessageImputBox = () => {

    const { sendMessage } = useChat()
    const [message, setMessage] = useState("")
    const [anchorEl, setAnchorEl] = useState<Element | null>(null)
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        setMessage(checkText(e.target.value))
    }

    const handleSend: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault()
        if(message){
            sendMessage(message)
            setMessage("")
        } 
    }

    const insertEmoji = (emoji: Emoji): void => {
        handleCloseEmojiWindow()
        setMessage((state) => {
            return state + emoji.emoji
        })
   
    }

    const handleOpenEmojiWindow: MouseEventHandler<Element> = (e) => {
        anchorEl ? setAnchorEl(null) : setAnchorEl(e.currentTarget)
    }

    const handleCloseEmojiWindow = (): void => {
        setAnchorEl(null)
    }

    return (
        <Box  component="form" onSubmit={handleSend} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px', padding: "10px", paddingRight: '25px'}}  noValidate >
            <IconButton aria-describedby={id} onClick={handleOpenEmojiWindow} >
                <InsertEmoticonIcon />
            </IconButton>
            <TextField fullWidth value={message} onChange={handleChange} id="messageInput" variant="outlined" sx={{height: "30px"}} color='primary' />
            <IconButton type="submit">
                <CustomSendIcon />
            </IconButton>
            <Popover sx={{transform: 'translate(0px, -50px)'}}
            id={id}
  open={open}
  anchorEl={anchorEl}
  onClose={handleCloseEmojiWindow}
  anchorOrigin={{
    vertical: 'top',
    horizontal: "left"
  }}
  transformOrigin={{
    vertical: 'bottom',
    horizontal: "left"
  }}
>

  <Stack direction="row" flexWrap="wrap" sx={{maxWidth: "300px", maxHeight: "300px"}}>
    {emojis.map((emoji) => 
        emoji.group === 0  && (
            <Tooltip title={emoji.label} key={emoji.hexcode}>
            <Typography fontSize={25} onClick={() => insertEmoji(emoji)} sx={{cursor: "pointer"}}>{emoji.emoji}</Typography>
            </Tooltip>
        
    ))}r
  </Stack>
</Popover>
        </Box>
    )
}

export default MessageImputBox