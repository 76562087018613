import { User } from "../../types/types"
import Box from "@mui/system/Box"
import Typography from '@mui/material/Typography';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import useLoginServices from "../../customHooks/useLoginServices";
import { Fragment } from "react";

const Visitors = ({ visitors }: {visitors: Array<User>}) => {

    const { loginState } = useLoginServices()

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            right: '10px',
            bottom: '0px',
            position: 'absolute'
         }}>
            { visitors.length > 1 && (
                <Fragment>
                    <Typography sx={{
                        fontStyle: 'oblique',
                        color: 'grey'
                    }}>Látta:</Typography>
                    <AvatarGroup>
                        {visitors.map(({ _id, username, imageid }) => loginState.id !== _id && (
                            <Tooltip key={_id} title={username}>
                                <Avatar src={`/images/${imageid}`} sx={{width: '18px', height: '18px'}} />
                            </Tooltip>
                        ))}
                    </AvatarGroup>
                </Fragment>
            )}
           
         </Box>
    )
}

export default Visitors