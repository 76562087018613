import { useState, useEffect, useReducer, createContext } from "react"
import useLoginServices from "./useLoginServices"
import useFetchAuth from './useFetchAuth';
import { formatChat, isVisited } from "../helpers";
import { removeFromArray, addArray } from "../helpers";
//import chatAlert from '../audio/chatAlert.mp3';
import { Chat, Message, User, AlertMap, ChatUtils, Inbox } from "../types/types"
import { MessageObject } from "../Class/Class";
import _ from 'lodash';
import { ChatInstance } from '../Class/Class';

export const ChatContext = createContext({} as ChatUtils)

const reducer = (state: Inbox, action: any): Inbox => {
    const { type } = action
    const payload = action.payload && action.payload
    
    switch (type) {
      case "setSelectedChat":
        const selectedChat = state.chatList.filter((chat) => chat._id === payload.chat_id)
        return {...state, selectedChat: selectedChat[0] ? selectedChat[0] : state.selectedChat}
      case "setMessages":
        return {...state, selectedChat: {...state.selectedChat, messages: payload.messages}}
      case "pushMessage":
        if(!payload.chat_id || (payload.chat_id === state.selectedChat._id)){
            state.selectedChat.messages.push(payload.msgObject)
        }
        return {...state}
      case "updateGroupChatParticipants":
        if(state.selectedChat._id === payload.chat_id){
            state.selectedChat.participants = payload.participants
        } 
        return {...state}
      case "addToList":
        const findedChat = state.chatList.filter(chat => chat._id === payload.chat._id)
        if(findedChat.length === 0){
            state.chatList.push(formatChat(payload.chat, payload.userid))
        }
        return {...state, chatList: [...state.chatList]} 
      case "setList": 
        return {...state, chatList: payload.chatList.map((chat: Chat) => formatChat(chat, payload.userid))} 
      case "sortList":
        return {...state, chatList: state.chatList.sort((a: Chat, b: Chat) => new Date(b.lastMessage.createdAt).getTime() - new Date(a.lastMessage.createdAt).getTime())}
      case "addVisitor":
        return {...state, chatList: state.chatList.map((room) => {
            if(payload.chat_id === room._id){
                !isVisited(room.visitors, payload.user.id) && room.visitors.push({_id: payload.user.id, username: payload.user.username, imageid: ''} as User)
            } 
            
            return room
        })}
        case "resetVisitors": 
            if(!_.isEmpty(state.selectedChat)) state.selectedChat.visitors = []
            return {...state, chatList: state.chatList.map(chat => {
                if(chat._id === payload.chat_id){
                    chat.visitors = []
                }
                return chat
            })}
        case "setUnvisited":
         return {...state, chatList: state.chatList.map((room) => {
            if(payload.chat_id === room._id && payload.chat_id !== state.selectedChat._id){
                room.visitors = room.visitors.filter(visitor => visitor._id !== payload.userid)
            } 
            return room
        })}  
       case "removeFromList":
        return {selectedChat: {} as Chat, chatList: state.chatList.filter((room) => room._id !== state.selectedChat._id)}  
       case "updateLastMessage":
        return {...state, chatList: state.chatList.map((room) => {
            if(room._id === payload.chat_id) room.lastMessage = payload.msgObject
            return room
        })} 
      default:
        return state;
    }
  };

const ChatApp = ({ children }) => {


    const [inbox, dispatch] = useReducer(reducer, {
        chatList: [],
        selectedChat: {} as Chat
    });
    const [online, setOnline] = useState<Array<string>>([])
    const [isFetchPending, setFetchPending] = useState(false)
   
    const [alertMap, setAlertMap] = useState<AlertMap>({
        private: {
            rooms: [],
            number: 0
        },
        group: {
            rooms: [],
            number: 0
        },
        all: {
            rooms: [],
            number: 0
        },
    })



    const { loginState } = useLoginServices()
    const fetchAuth = useFetchAuth()

    const sendMessage = (message: string) => {
        fetchAuth.post("/chat/message", { chatId: inbox.selectedChat._id, message })
        const msgObject: Message = new MessageObject(0, message, loginState)
        dispatch({type: "pushMessage", payload: { msgObject }})
        dispatch({type: "updateLastMessage", payload: {chat_id: inbox.selectedChat._id, msgObject}})
        dispatch({type: "sortList"})

    }
    

    const leaveRoom = () => {
        fetchAuth.put(`/chat/close`, {chatId: inbox.selectedChat._id}).then(() => {
            dispatch({type: "removeFromList"})
        })
    }

    const joinGroupChat = (chat_id: string) => {

        const findedChat = inbox.chatList.filter(chat => chat._id === chat_id)

        if(findedChat.length === 0){
            fetchAuth.get<Chat>(`/chat/group/join/${chat_id}`).then(({ data }) => {      
                    dispatch({type: "addToList", payload: {chat: data, userid: loginState.id}})
                    dispatch({type: "sortList"})
                    dispatch({type: "setSelectedChat", payload: {chat_id: data._id}} )
                    fetchAuth.get<Array<Message>>(`/chat/messages/${data._id}`).then(({ data }) => {
                        dispatch({type: "setMessages", payload: {messages: data}} )
                    })
            })
            .catch((err) => {
                console.log(err)
                setFetchPending(false)
            })
        }else{
            handleRoomChange(findedChat[0]._id)
        }

  
    }

    const newPrivateChat = (userid: string, username: string) => {

        const findedChat = inbox.chatList.filter(chat => chat.participants[0]._id === userid || chat.participants[1]._id === userid)

        if(findedChat.length === 0){
            fetchAuth.post<Chat>(`/chat/private`, {userdata: {id: userid, username}} ).then(({ data }) => {           
                    dispatch({type: "addToList", payload: {chat: data, userid: loginState.id}})
                    dispatch({type: "sortList"})
                    dispatch({type: "setSelectedChat", payload: {chat_id: data._id}} )
                    fetchAuth.get(`/chat/messages/${data._id}`).then(({ data }) => {
                        dispatch({type: "setMessages", payload: {messages: data}} )
                    })
            })
            .catch((err) => {
                console.log(err)
                setFetchPending(false)
            })
        }else{
            handleRoomChange(findedChat[0]._id)
        }
    }
    
    const handleRoomChange = (roomId: string) => {
        if(!inbox.selectedChat || (inbox.selectedChat._id !== roomId)){
            dispatch({type: "setSelectedChat", payload: {chat_id: roomId}} )
            dispatch({type: "addVisitor", payload: {user: loginState, chat_id: roomId}})
            fetchAuth.get<Array<Message>>(`/chat/messages/${roomId}`).then(({ data }) => {
                dispatch({type: "setMessages", payload: {messages: data}} )
            })
        }  
    }

    useEffect(() => {

        const { socket } = new ChatInstance()

        socket.on("message", (msgObject: Message, chatId: string) => {
            dispatch({type: "updateLastMessage", payload: {chat_id: chatId, msgObject}})
            dispatch({type: "pushMessage", payload: {chat_id: chatId, msgObject }})
            dispatch({type: "sortList"})

            if(inbox.selectedChat._id === chatId){
                if(!isVisited(inbox.selectedChat.visitors, loginState.id)){
                    dispatch({type: "addVisitor", payload: {chat_id: chatId, user: loginState }})
                    fetchAuth.post(`/chat/addVisitor/${chatId}`)
                } 
            }else{
                dispatch({type: "setUnvisited", payload: {chat_id: chatId, userid: loginState.id}})
            }
        })

        socket.on("update participants", (participants: Array<User>, chatId: string) => {
            dispatch({type: "updateGroupChatParticipants", payload: {chat_id: chatId, participants}})
        })

        socket.on("newVisitor", (visitor: User, chatId: string) => {
            dispatch({type: "addVisitor", payload: {visitor: visitor, chat_id: chatId}})
        })

        socket.on("resetVisitors", (chat_id: string) => {
            dispatch({type: "resetVisitors", payload: {chat_id}})
            inbox.chatList.forEach((chat) => {
                if(chat._id === chat_id){
                    if(inbox.selectedChat._id === chat_id){
                        if(!isVisited(inbox.selectedChat.visitors, loginState.id)){
                            dispatch({type: "addVisitor", payload: {chat_id, user: loginState }})
                            fetchAuth.post(`/chat/addVisitor/${chat_id}`)
                        } 
                    }
                }
                
           
            })

        })
    


        socket.on("newChat", (chat: Chat) => {
            dispatch({type: "addToList", payload: {chat, userid: loginState.id}}) 
            dispatch({type: "sortList"})
        })

        socket.on("goOffline", (userid: string) => setOnline(state => removeFromArray([...state], [userid])))
        socket.on("goOnline", (userid: string) => setOnline((state) => addArray([...state], userid)))


        fetchAuth.get<Array<Chat>>("/chat").then(({ data }) => {
            dispatch({type: "setList", payload: { chatList: data, userid: loginState.id }})
            
        })

        fetchAuth.get<Array<string>>("/chat/online").then(({data}) => {
            setOnline(data)
        })

        return () => { socket.close() }
          
    }, [])

    useEffect(() => {

        console.log(inbox.chatList)

        if(inbox.chatList){
            setAlertMap(state => {
                const newState ={
                    private: inbox.chatList.filter(room => room.type === "private" && !isVisited(room.visitors, loginState.id)), 
                    group: inbox.chatList.filter(room => room.type === "group" && !isVisited(room.visitors, loginState.id))
                }
/*
                if((state.private.length < newState.private.length) || (state.group.length < newState.group.length)){
                    audio.play()
                }
                */
                return {
                    private: {
                        rooms: newState.private,
                        number: newState.private.length
                    },
                    group: {
                        rooms: newState.group,
                        number: newState.group.length
                    },
                    all: {
                        rooms: [...newState.private, ...newState.group],
                        number: newState.private.length + newState.group.length
                    }
                }
            })
           
        }
    }, [inbox.chatList])

    return <ChatContext.Provider value={{
        online, 
        setOnline, 
        inbox,
        dispatch, 
        leaveRoom,
        newPrivateChat,
        handleRoomChange,
        sendMessage,
        isFetchPending,
        alertMap,
        joinGroupChat
    }}>
    {children}
    </ChatContext.Provider>
}

export default ChatApp