import { User, Message, Chat } from "../types/types";
import { io } from "socket.io-client";
import { serverURL } from "../axios/API";

export class MessageObject implements Message {

    type: number;
    message: string;
    createdAt: string;
    updatedAt: string;
    user: User;

    constructor(type: number, message: string, loginstate: any){
        this.type = type;
        this.message = message;
        this.createdAt = new Date().toISOString();
        this.updatedAt = new Date().toISOString();
        this.user = {
            username: loginstate.username,
            _id: loginstate.id,
            imageid: '',
            createdAt: '',
            updatedAt: ''
        }
    }
}

interface ChatListClass {
    chatList: Array<Chat>
}

export class ChatInstance {
    socket = io(serverURL, {
        autoConnect: false,
        withCredentials: true,
      });

    constructor(){
        this.socket.connect()
    }

    close(){
        this.socket.close()
    }

}