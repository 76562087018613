import { Chat, Message, User } from "./types/types"


export const formatChat = (chat: Chat, userid: string): Chat => {
 
    if(chat.type === "private"){
        chat.partner = (chat.participants[0]._id === userid) ? chat.participants[1] : chat.participants[0]
        chat.name = chat.partner.username
        chat.imageid = chat.imageid ? chat.imageid : chat.partner.imageid
    }
    
    chat.messages = []

    return chat
}

export const isVisited = (visitors: Array<User>, userid: string): boolean => {
 
    let exist = false

    visitors.forEach(visitor => {
        if(visitor._id === userid){
            exist = true
        }
    })

    return exist
}

export const getUnixTimestamp = (date: Date | string): number => {
    if(date instanceof Date){
        return Math.ceil(date.getTime() / 1000)
    }else{
        return Math.ceil(Date.parse(date) / 1000)
    }
}

export const getLocalDateTime = (ISO8601Time: string) => {
    const dateObj = new Date(ISO8601Time);
    const currentDate = new Date()
    if(dateObj.toISOString().slice(0, 10) === currentDate.toISOString().slice(0, 10)){
        return dateObj.toLocaleTimeString('hu-HU', {	
            hour: 'numeric',
            minute: 'numeric'
    })
    }
    else if(getUnixTimestamp(currentDate) - getUnixTimestamp(dateObj) < (60 * 60 *24 * 6)){
        return dateObj.toLocaleDateString('hu-HU', {
            weekday: 'short',
            hour: 'numeric',
            minute: 'numeric'
        })
    }else{
        return dateObj.toLocaleDateString('hu-HU', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        })
    }
 
}

export const getFullLocalDateTime = (ISO8601Time: string) => {
    const dateObj = new Date(ISO8601Time);


    
    return dateObj.toLocaleDateString('hu-HU', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    })
   
 
}

export const removeFromArray = <T>(array: Array<T>, removed: Array<T>): Array<T> => array.filter((e) => !removed.includes(e))
export const addArray = <T>(array: Array<T>, target: T, mode = "array"): Array<T> => (!array.includes(target) || (mode === "set")) ? [...array, target] : array
export const reduceChat = (messages: Array<Message>) =>  messages.reduce((acc: Array<any>, message, index, array) => messageReductor(acc, message, {...array[index-1]}, index), [])


/*
export const adMsgToReducedArray = (reducedMessages: Array<Array<Message>>, message: Message) => {
    const prev = {...reducedMessages[reducedMessages.length-1][reducedMessages[reducedMessages.length-1].length-1]}
    return messageReductor(reducedMessages, message, prev) 
}*/

export const messageReductor = (acc: Array<Array<Message>>, curr: Message, prev: Message, index: boolean | number = false) => {
    if(index !== false && index === 0){
        return [[{type: 2, message: getLocalDateTime(curr.createdAt)}], [curr]]
    }
    else if(((typeof prev.user === 'object' && typeof curr.user === 'object' && (prev.user._id !== curr.user._id))  || curr.type === 1) && ((getUnixTimestamp(curr.createdAt) - getUnixTimestamp(prev.createdAt)) > 3600)){
        return [...acc, [{type: 2, message: getLocalDateTime(curr.createdAt)}], [curr]] 
    }else if(((typeof prev.user === 'object' && typeof curr.user === 'object' && (prev.user._id !== curr.user._id) ) || curr.type === 1)){
        return [...acc, [curr]]
    }else if(((getUnixTimestamp(curr.createdAt) - getUnixTimestamp(prev.createdAt)) > 3600)){
        return [...acc, [{type: 2, message: getLocalDateTime(curr.createdAt)}], [curr]] 
    }else{
        return [...acc.slice(0, acc.length - 1) , [curr, ...acc[acc.length - 1]]]
    }
}


export const sliceLastMessage = (string) => (string.length > 20) ? `${string.substring(0, 20)}...` : string
