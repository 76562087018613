import axios from "axios"

export const serverURL = process.env.REACT_APP_MODE === "DEV" ? "http://localhost:6060" : ""
export const apiURL = serverURL + '/api'

const API = axios.create({
    baseURL: apiURL,
});

export default API

