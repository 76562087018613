import { useContext } from "react"
import { ChatContext } from "./ChatApp"

const useChat = () => {

    const chatInstance = useContext(ChatContext)

    return chatInstance
}

export default useChat