import MessageSlice from "./MessageSlice"
import Box from "@mui/system/Box"
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import { reduceChat } from "../../../helpers";
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import useChat from "../../../customHooks/useChat";
import Visitors from "../../elements/Visitors";

const MessageContainer = styled(Box)(({ theme }) => ({
    overflowY: "auto",
    overflowX: "hidden",
    padding: "20px 5px 20px 5px",
    display: "flex",
    flexFlow: "column-reverse",
    flexGrow: 1,
    position: 'relative'
}))

const CustomList = styled(List)(({ theme }) => ({
    display: "flex",
    flexFlow: "column-reverse",
    marginBottom: '10px'
}))


const MessageBox = () => {

    const { inbox } = useChat()
    

    return inbox.selectedChat.messages.length !== 0 ? (
            <MessageContainer> 
            {[...reduceChat(inbox.selectedChat.messages)].reverse().map((slice, index) =>
            <CustomList key={index}>
                <MessageSlice slice={slice} /> 
            </CustomList>
             )}
             <Visitors visitors={inbox.selectedChat.visitors} />
       </MessageContainer>
        ) : 
        ( 
        <Stack justifyContent="center" alignItems="center" flexGrow={1}>
            <CircularProgress />
        </Stack>
        
        )
        
    
}

export default MessageBox